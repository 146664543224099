import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from '../services/canonical.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
    private title: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService,
    ) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.title.setTitle('Privacy Policy');
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Tradey Bay privacy policy',
    });

  }

}
