import { Component, Input, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import { CanonicalService } from '../services/canonical.service';
import { AfterViewInit, ViewChild } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import AOS from "aos";



// import * as dataJSON from './carousel_images.json';

/**
 * @title Basic cards
 */

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbCarouselConfig], // add NgbCarouselConfig to the component providers
})
export class HomeComponent implements OnInit {

  constructor(
    config: NgbCarouselConfig,
    private title: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService,
  ) {
    // customize default values of carousels used by this component tree
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
    config.interval = 8000; // images change in 8sec //
    config.wrap = true; // autometically redirect to first image //
    config.keyboard = true;
    config.pauseOnHover = true;
    
  }

  // Carousel
  showNavigationArrows = false;
  showNavigationIndicators = false;
  public images = [
    'https://res.cloudinary.com/sputnik-tech/image/upload/v1690552551/Untitled%20design/lisheng-chang-M2524ncJQ40-unsplash_1_csiuf4.jpg',
    'https://res.cloudinary.com/sputnik-tech/image/upload/v1690552551/Untitled%20design/pexels-omotayo-tajudeen-3213283_nsjn84.jpg',
    'https://res.cloudinary.com/sputnik-tech/image/upload/v1690552549/Untitled%20design/pexels-kindel-media-6868621_a01wwl.jpg',
    'https://res.cloudinary.com/sputnik-tech/image/upload/v1690552540/Untitled%20design/pexels-kindel-media-6869055_gbqk3p.jpg',
  ];
  titles = ['iOS', 'Android', 'Web Apps', 'Cross-platform Apps'];
  discription = [
    'iOS mobile applications',
    'Android mobile applications',
    'Any type of website',
    'Same app working on iOS, Android and Web ',
  ];

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.title.setTitle('Tradey Bay');
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Welcome to Tradey Bay, Where everything meets everyone',
    });
    AOS.init();  
  } 
}




