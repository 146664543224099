// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  spaceId: 'hd0ncmvnr3yz',
  accessToken: 'VhjE8NIX0aP8Fdw7dYa_W2t_lSjf4QBUEboplrWZD5o',
  projspaceId: 'hl73y16ikezt',
  projaccessToken: '2Preg45GNM5O15Wcjee4UIkE-SautSpskZRemzU9EF0',
  teamspaceId: '1vbzo9c2gopz',
  teamaccessToken: 'cpO1DqLpMhTYjjjzg79k4VbWMBQyr_wM1_6rjsEchM4',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
