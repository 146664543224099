<link rel="stylesheet" href="https://unicons.iconscout.com/release/v4.0.0/css/line.css">
<link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.0/css/all.min.css" rel="stylesheet" />
<!-- Footer -->
<footer  class="page-footer font-small blue-grey lighten-5">

    

    <!-- Footer Links -->
    <div class="container text-center text-md-left mt-5">

        <!-- Grid row -->
        <div class="row mt-3 dark-grey-text">

            <!-- Grid column -->
            <div class="col-md-3 col-lg-4 col-xl-3 mb-4">

                <!-- Content -->
                <h6 class="text-uppercase font-weight-bold">Tradey Bay</h6>
                <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                <p style="text-align:center;">Tradey Bay is an innovative online marketplace and e-commerce platform where you can buy, sell, and trade a wide variety of products. With unique features like Auction Ads, Homepage Carousel Ads, and Listing Boosting, Tradey Bay offers a superior online shopping experience. Powered by<a style="text-decoration:none"  href="https://sputniktech.co/"> Sputnik Tech</a>.</p>
                

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                <!-- Links -->
                <h6 class="text-uppercase font-weight-bold">Pages</h6>
                <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="/">Home</a>
                </p>
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="/terms-and-conditions">Terms and Conditions</a>
                </p>
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="/privacy-policy">Privacy Policy</a>
                </p>
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="/pricing-policy">Pricing Policy</a>
                </p>
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="/dispute-resolution-policy">Dispute Resolution Policy</a>
                </p>
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="/contact-us">Contact Us</a>
                </p>
                
                
            </div>
            
            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                
                <h6 class="text-uppercase font-weight-bold">Social Media</h6>
                <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="https://www.facebook.com/profile.php?id=100095259081192"><i class="fab fa-2x fa-facebook-square"></i></a>
                </p>
                
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="https://www.instagram.com/tradeybay/?utm_source=qr&igshid=ZDc4ODBmNjlmNQ%3D%3D"><i class="fab fa-2x fa-instagram-square"></i></a>
                </p>
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="https://twitter.com/tradeybay"><i class="fab fa-2x fa-twitter"></i></a>
                </p>
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="https://www.linkedin.com/company/tradey-bay"><i class="fab fa-2x fa-linkedin"></i></a>
                </p>
                
                
                
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-4">

                <!-- Links -->
                <h6 class="text-uppercase font-weight-bold">Contact Us</h6>
                <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                <!-- <p>
                    <a  href="https://wa.me/message/4QJ2NGZ7FJQZF1">
                    <i style="font-size:28px; color: #6750A4;" class="uil uil-whatsapp"></i> Ru <i class="fas fa-mouse ms-2"></i>
                </a>
                </p>
                <p>
                    <a  href="https://wa.me/message/4B662PIKJJV4K1">
                    <i style="font-size:28px; color: #6750A4;" class="uil uil-whatsapp"></i> Eng <i class="fas fa-mouse ms-2"></i>
                </a>
                </p> -->
                
                <!-- <p>
                  <a href="tel:+27682233859">
                    <i style="font-size:25px; color: #6750A4;" class="uil uil-phone"></i> +27 68 223 3859
                </a>  
                </p> -->
                <p>
                    <i style="color: #6750A4" class="fas fa-2x fa-map-marked-alt me-2"></i> 292 Surrey Avenue, Randburg
                </p>
                <p>
                    <i style="color: #6750A4" class="fas fa-2x fa-envelope me-2"></i> info@tradeybay.com
                </p>
                
                
                
                


            </div>
            <!-- Grid column -->

        </div>
        <!-- Grid row -->

    </div>
    <!-- Footer Links -->
    <!-- Copyright -->
    <div class="footer-copyright text-center  py-3"><i class="uil uil-brackets-curly"></i> Designed, owned and created by
        <a style="text-decoration:none"  href="https://sputniktech.co/"> Sputnik Tech</a>
    </div>
    <!-- Copyright -->

    <!-- Copyright -->
    <div class="footer-copyright text-center  py-3">© {{ currentYear }} All rights reserved
        <a style="text-decoration:none" class="dark-grey-text"> Tradey Bay</a>
    </div>
    <!-- Copyright -->

</footer>
<!-- Footer -->