import { Component, OnInit } from '@angular/core';

import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from '../services/canonical.service';
import AOS from 'aos';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  constructor(
    private title: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService
  ) {}

  ngOnInit(): void {
    AOS.init();
    this.canonicalService.setCanonicalURL();
    this.title.setTitle('Contact Us');
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Tradey Bay contact us page',
    });
  }

  
}
