import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Inject, Optional, PLATFORM_ID} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DarkModeToggleComponent } from './dark-mode-toggle/dark-mode-toggle.component';
import { DarkModeModule } from './services/dark-mode-services/dark-mode.module';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PricingPageComponent } from './pricing-page/pricing-page.component';
import { DisputeResolutionPolicyComponent } from './dispute-resolution-policy/dispute-resolution-policy.component';
import { RedirectingComponent } from './redirecting/redirecting.component';


// Available options
// interface NgxSpinnerConfig {
//   type?: string;
// }



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    FooterComponent,
    HomeComponent,
    NavBarComponent,
    ContactUsComponent,
    DarkModeToggleComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    PricingPageComponent,
    DisputeResolutionPolicyComponent,
    RedirectingComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp'}),
    // Specify ng-circle-progress as an import
    
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DarkModeModule,
    
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}


