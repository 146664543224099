<div class="container mt-5 mb-5">
    <h1>Dispute Resolution Policy for Tradey Bay</h1>
    <section>
        <h2>1. Introduction</h2>
        <p>At Tradey Bay, we want our users to have a smooth and satisfactory experience. We understand that conflicts may arise between buyers and sellers. This Dispute Resolution Policy has been created to address these conflicts in a fair and efficient manner.</p>
    </section>
    <section>
        <h2>2. Initiation of a Dispute</h2>
        <p>Users have 7 working days following a transaction to initiate a dispute. Before initiating a formal dispute, we encourage our users to communicate with each other directly to try and resolve the problem.</p>
    </section>
    <section>
        <h2>3. Tradey Bay's Role</h2>
        <p>Tradey Bay acts as a neutral facilitator in the dispute resolution process. Our role is to ensure that both parties communicate effectively in trying to resolve the dispute.</p>
    </section>
    <section>
        <h2>4. Reviewing a Dispute</h2>
        <p>Once a dispute has been filed, Tradey Bay will check all the evidence then pass a resolution. We may ask both parties for additional information, such as screenshots. Each party is expected to cooperate fully and provide the necessary documentation.</p>
    </section>
    <section>
        <h2>5. Resolution of Dispute</h2>
        <p>Tradey Bay will aim to resolve the dispute within 7 working days. Decisions are based on the evidence provided by both parties. Once the decision is made, it is final and binding.</p>
    </section>
    <section>
        <h2>6. Cooperation with Law Enforcement</h2>
        <p>In cases of serious violations, Tradey Bay will cooperate with the appropriate law enforcement agencies. This can include cases of fraud, theft, and other illegal activities.</p>
    </section>
    <section>
        <h2>7. Changes to this Policy</h2>
        <p>Tradey Bay reserves the right to modify this policy at any time. Changes will be communicated on our Platform.</p>
    </section>
</div>

<div class="custom-shape-divider-bottom-1667156080">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
</div>
<div class="custom-shape-divider-top-1667156508">
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
      <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
      <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
  </svg>
</div>