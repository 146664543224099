
    

    <section id="showcase">
        <div class="container">
            <h1>Terms and Conditions for Tradey Bay</h1>
            <p>Please read our terms and conditions carefully before using our website and mobile application.</p>
        </div>
    </section>

    <section class="container mt-5">
        <h2>1. Introduction</h2>
        <p>These Terms and Conditions ("Terms") govern your use of the Tradey Bay website and mobile application ("Platform"), owned and operated by Sputnik Tech ("us", "we", or "our"). Please read these Terms carefully before using our Platform.</p>
        <p>By accessing or using the Platform, you agree to be bound by these Terms. If you disagree with any part of the Terms, then you may not access the Platform.</p>

        <h2>2. User Accounts</h2>
        <p>To use certain features of our Platform, you must register for an account. When you register for an account, you may be required to provide us with some information about yourself, such as your phone number, email address, address, proof of identity (Passport, Drivers License, ID), location and Business information (Certificate of incorparation and so on) if you are a company. You agree that the information you provide to us is accurate and that you will keep it accurate and up-to-date at all times.</p>
        <p>You are solely responsible for maintaining the confidentiality of your account, and you accept responsibility for all activities that occur under your account.</p>
        
        <h2>3. Listings and Transactions</h2>
        <p>Users can list items for sale or auction on our Platform. All listings must be legal and must comply with all applicable local, state, national, and international laws.</p>
        <p>Tradey Bay is not involved in the transaction between buyers and sellers. As a result, Tradey Bay has no control over the quality, safety, or legality of the items advertised, the truth or accuracy of the listings, or the ability of sellers to sell items or buyers to buy items.</p>
        
        <h2>4. Payments, Fees, and Services</h2>
        <p>Listing fees and other service charges are described on our <a style="color: #6750A4;"  routerLink="/pricing-policy">Pricing page</a>. Changes to our fee policy will be posted on our Platform 14 days in advance, and it is the user's responsibility to stay updated with these changes. All payments made are final and non-refundable, unless otherwise specified.</p>
        
        <h2>5. User Conduct</h2>
        <p>Users agree to behave in a respectful and legal manner when using our Platform. Harassment, vulgar language, hate speech, or other non-professional behavior will not be tolerated and may result in account termination.</p>

        <h2>6. Intellectual Property Rights</h2>
        <p>All content on our Platform, including text, graphics, logos, images, and software, is the property of Sputnik Tech or its content suppliers and is protected by international copyright laws.</p>

        <h2>7. Limitation of Liability</h2>
        <p>In no event shall Sputnik Tech, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your use of the Platform.</p>
        
        <h2>8. Privacy</h2>
        <p>Your privacy is important to us. Please review our <a style="color: #6750A4;"  routerLink="/privacy-policy">Privacy Policy</a> for information about how we collect, use, and disclose your personal information.</p>

        <h2>9. Dispute Resolution</h2>
        <p>Any disputes arising out of or related to these Terms of Service or our services will be handled in accordance with our <a style="color: #6750A4;"  routerLink="/dispute-resolution-policy">Dispute Resolution Policy</a>.</p>

        <h2>10. Changes to Terms</h2>
        <p>We reserve the right to modify these Terms at any time. When we do, we will revise the updated date at the bottom of this page.</p>
        
        <h2>11. Termination</h2>
        <p>We may terminate your access to the Platform, without cause or notice, which may result in the forfeiture and destruction of all information associated with your account.</p>
        
        <p>By using Tradey Bay, you are agreeing to these terms and conditions in full.</p>
        <p>Last Updated: [29-07-2023]</p>
    </section>
    <div class="custom-shape-divider-bottom-1667156080">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
            <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
            <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
        </svg>
    </div>
    <div class="custom-shape-divider-top-1667156508">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
          <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
          <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
      </svg>
    </div>
