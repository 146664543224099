import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirecting',
  templateUrl: './redirecting.component.html',
  styleUrls: ['./redirecting.component.scss']
})
export class RedirectingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
