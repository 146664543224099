import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from '../services/canonical.service';

@Component({
  selector: 'app-pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss']
})
export class PricingPageComponent implements OnInit {

  constructor(
    private title: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService,
  ) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.title.setTitle('Pricing Policy');
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Tradey Bay pricing policy',
    });
  }

}
