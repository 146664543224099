import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HomeComponent } from './home/home.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PricingPageComponent } from './pricing-page/pricing-page.component';
import { DisputeResolutionPolicyComponent } from './dispute-resolution-policy/dispute-resolution-policy.component';
import { RedirectingComponent } from './redirecting/redirecting.component';



const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent
  },
  {
    path: 'dispute-resolution-policy',
    component: DisputeResolutionPolicyComponent 
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'redirecting',
    component: RedirectingComponent
  },
  {
    path: 'pricing-policy',
    component: PricingPageComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
  {
    path: "**",
    redirectTo: "",
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
