<section id="showcase">
    <div class="container">
        <h1>Privacy Policy for Tradey Bay</h1>
        <p>Please read our privacy policy carefully before using our platform.</p>
    </div>
</section>
<div class="container mt-5">
    <section>
        <h2>1. Introduction</h2>
        <p>Welcome to Tradey Bay's Privacy Policy. Sputnik Tech respects your privacy and is committed to protecting your personal data. This Privacy Policy will tell you how we handle and look after your personal data when you visit our platform and tell you about your privacy rights and how the law protects you.</p>
    </section>
    <section>
        <h2>2. Data Collection</h2>
        <p>We collect various types of information in connection with the services, including:</p>
        <ul>
            <li>Information you provide directly to us.</li>
            <li>Information we collect about your use of our services.</li>
            <li>Information we obtain from third-party sources.</li>
        </ul>
    </section>
    <section>
        <h2>3. Data Usage</h2>
        <p>We use the information we collect to:</p>
        <ul>
            <li>Provide, maintain, and improve our services.</li>
            <li>Send you technical notices and support messages.</li>
            <li>Communicate with you about products, services, promotions, and events.</li>
        </ul>
    </section>
    <section>
        <h2>4. Data Sharing</h2>
        <p>We do not share your personal information with third parties except in circumstances such as legal requirements, security, or business transfers.</p>
    </section>
    <section>
        <h2>5. Data Security</h2>
        <p>We are committed to protecting the security of your personal information. We use various security technologies and procedures to protect your personal information from unauthorized access, use, or disclosure.</p>
    </section>
    <section>
        <h2>6. Cookies and Similar Technologies</h2>
        <p>We use cookies and similar technologies to provide our services and help collect data. You have the right to choose whether or not to accept cookies.</p>
    </section>
    <section>
        <h2>7. Your Rights</h2>
        <p>Depending on where you live, you may have certain rights in relation to your personal information. These may include the right to access, correct, delete, or restrict use of your personal information.</p>
    </section>
    <section>
        <h2>8. Changes to This Policy</h2>
        <p>We may periodically revise this Privacy Policy. We will post any changes on this page.</p>
    </section>
    
</div>
<div class="custom-shape-divider-bottom-1667156080">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
</div>
<div class="custom-shape-divider-top-1667156508">
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
      <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
      <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
  </svg>
</div>