import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from '../services/canonical.service';

@Component({
  selector: 'app-dispute-resolution-policy',
  templateUrl: './dispute-resolution-policy.component.html',
  styleUrls: ['./dispute-resolution-policy.component.scss']
})
export class DisputeResolutionPolicyComponent implements OnInit {

  constructor(
    private title: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService,
  ) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.title.setTitle('Dispute Resolution Policy');
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Tradey Bay dispute resolution policy',
    });
  }

}
