
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
<link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.0/css/all.min.css" rel="stylesheet" />

<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->


<div class="page-section mt-5">
    <div  class="container mb-5">
      <div class="row">
        <div class="col-lg-6 py-3 wow fadeInLeft">
          <div class="subhead">Contact Us to learn more about</div>
          <h2 data-aos="flip-left" data-aos-duration="1000" class="title-section"><span class="marked">Tradey Bay</span></h2>
          <div class="divider"></div>
          <p class="mb-5"><i style="color: #6750A4;" class="fas fa-arrow-alt-circle-right "></i> Contact us and here more about Tradey Bay</p>
          <div class="find-widget">
            <i style="color: #6750A4" class="fas fa-2x fa-map-marked-alt me-2"></i><a >292 Surrey Avenue, Randburg</a>
            </div>
            <div class="find-widget">
                <i style="color: #6750A4" class="fas fa-2x fa-envelope-open-text my-3 me-2 "></i><a> info@tradeybay.com</a>
            </div>
            <!-- <div  class="find-widget">
            <i style="color: #6750A4" class="fas fa-2x fa-phone my-3 me-2 "></i><a href="tel:+27682233859" style="text-decoration: none;color: #6750A4;"> +27 68 223 3859</a>
            
            </div>
            <div class="find-widget">
                <i style="color: #6750A4" class="fab fa-2x fa-whatsapp-square my-3 me-2 "></i><a style="text-decoration: none;color: #6750A4;" href="https://wa.me/message/4QJ2NGZ7FJQZF1" >Ru <i class="fas fa-mouse ms-2"></i></a> 
            </div>
            <div class="find-widget">
              <i style="color: #6750A4" class="fab fa-2x fa-whatsapp-square my-3 me-2 "></i><a style="text-decoration: none;color: #6750A4;" href="https://wa.me/message/4B662PIKJJV4K1" >Eng<i class="fas fa-mouse ms-2"></i></a> 
          </div> -->
          <a  [routerLink]="['/']" class="btn btn-primary btn-split my-5 ml-2">Go back home <div class="fab"><span class="fas fa-arrow-alt-circle-left" ></span></div></a>
        </div>
        <div  class="col-lg-6 py-3 wow zoomIn">
          <div class="img-place text-center">
            
            <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
            <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_gqeih8ct.json" background="transparent" speed="2"
                style="width: 350px; height: 350px;" loop autoplay></lottie-player>
          </div>
          
        </div>
      </div>
    </div>
    <!-- <div class="relative" style="height: 36px;overflow: hidden;margin-top: -36px; z-index:2">
      <div class="w-full absolute bottom-0 start-0 end-0"
        style="transform: scale(2);transform-origin: top center;color: #6750A4;">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div> -->
    <div class="custom-shape-divider-bottom-1667156080">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
          <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
          <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
      </svg>
  </div>
  <div class="custom-shape-divider-top-1667156508">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
  </div>
     <!-- .container -->
  </div> <!-- .page-section -->




