<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
<link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.0/css/all.min.css" rel="stylesheet" />


<header class="mt-5">
  <div class="page-section mt-5">
    <div  class="container ">
      <div class="row">
        <div class="col-lg-6 py-3 wow fadeInLeft">
          <h2  class="mb-4"  class="title-section">Discover <span class="marked">Tradey Bay</span> Classifieds Marketplace !!!</h2>
          <div class="divider"></div>
          <p  class="text-lg mb-5"><i style="color: #6750A4;" class="fas fa-arrow-alt-circle-right "></i> Tradey Bay: Revolutionizing trade for all, big and small – your ultimate marketplace for everything.</p>
          <p  class="text-lg mb-5"><i style="color: #6750A4;" class="fas fa-arrow-alt-circle-right "></i> Discover Tradey Bay: Where everything meets everyone – the game-changing marketplace for goods and services.</p>
          <a  [routerLink]="['/contact-us']" class="btn btn-primary btn-split my-3 ml-2 me-2">Download Android app <i class="fab fa-google-play"></i></a>
          <a  [routerLink]="['/terms-and-conditions']" class="btn btn-primary btn-split ml-2">Download iOS app <i class="fab fa-app-store-ios"></i></a>
        </div>
        <div class="col-lg-6 py-3 wow zoomIn">
          <div class="img-place text-center">
            <ngb-carousel *ngIf="images" [showNavigationArrows]="showNavigationArrows"
                [showNavigationIndicators]="showNavigationIndicators">
                <ng-template ngbSlide *ngFor="let image of images; index as i; index as j">
                  <div class="picsum-img-wrapper">
                    <img  [src]="image" alt="Random slide" class="pic"  style="border-radius: 15px;" />
                  </div>
                </ng-template>
              </ngb-carousel> 
          </div>
        </div>
      </div>
    </div>
    <!-- .container -->
  </div> <!-- .page-section -->
  
  <div class="custom-shape-divider-bottom-1667156080">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
</div>
<div class="custom-shape-divider-top-1667156508">
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
      <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
      <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
  </svg>
</div>
</header>

<main>
  <section class="services section-bg " id="services">
    <div  class="container my-5">
      <header class="section-header">
        <div class="subhead"  >Some of the major</div>
        <h2 data-aos="flip-left" data-aos-duration="1000" class="title-section" >Features<span class="marked"> Tradey Bay</span> offers</h2>
        <div class="divider"></div>
      </header>
      <div class="row">
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="icon" style="background: #6750A4;">
              
              <i class="fas fa-gavel service-icon" style="color: #d4bedd;"></i>
            </div>
  
            <h4 class="title">Real-Time Auctions</h4>
  
            <p class="description">Tradey Bay offers an engaging auction platform, where users can bid on items in real-time. Sellers can list their items for auction easily, and buyers can participate in these auctions to secure items at potentially lower prices.</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="icon" style="background: #6750A4;">
              
              <i class="fas fa-robot service-icon" style="color: #d4bedd;"></i>
            </div>
  
            <h4 class="title">AI-Powered Recommendations </h4>
  
            <p class="description">Leveraging advanced artificial intelligence algorithms, Tradey Bay provides personalized recommendations to users. These suggestions are based on the user's browsing history, previous purchases, and trending items, making the shopping experience highly customized and convenient.</p>
          </div>
        </div>
  
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="icon" style="background: #6750A4;">
              
              <i class="fas fa-map-marker-alt service-icon" style="color: #d4bedd;"></i>
            </div>
  
            <h4 class="title">Location-Aware Shopping</h4>
  
            <p class="description">Tradey Bay's platform is integrated with location awareness technology. This feature allows users to discover local sellers and products, fostering community trade and allowing for quick and easy pickup of items.</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="icon" style="background: #6750A4;">
              
              <i class="fas fa-store service-icon" style="color: #d4bedd;"></i>
            </div>
  
            <h4 class="title">Versatile Marketplace</h4>
  
            <p class="description"> Tradey Bay caters to a wide array of goods and services. Whether it's real estate, hair styling services, or handcrafted items, our platform accommodates all, making it a truly comprehensive marketplace for every user's need.</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="icon" style="background: #6750A4;">
              
              <i class="fas fa-credit-card service-icon" style="color: #d4bedd;"></i>
            </div>
  
            <h4 class="title">Secure Payment Gateway</h4>
  
            <p class="description">Tradey Bay places a high priority on transaction safety. The platform uses secure encryption and trusted payment gateway services to ensure that user financial information is protected and transactions are secure.</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="icon" style="background: #6750A4;">
              
              <i class="fas fa-smile service-icon" style="color: #d4bedd;"></i>
            </div>
  
            <h4 class="title">Community Engagement Tools</h4>
  
            <p class="description">Tradey Bay offers features that facilitate connection and collaboration among its users. These include ratings and reviews for buyers and sellers, discussion forums, and live chat options. These features help create a trustworthy, supportive, and vibrant trading community.</p>
          </div>
        </div>
  
        
  
  
      </div>
    </div>
   
    <div class="custom-shape-divider-bottom-1667156080">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
          <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
          <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
      </svg>
  </div>
  <div class="custom-shape-divider-top-1667156508">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
  </div>
  </section>






  
  <div class="page-section">
    <div  class="container mb-5">
      <div class="row">
        <div class="col-lg-6 py-3 wow zoomIn">
          <div class="img-place  text-center">
            <img src="https://res.cloudinary.com/sputnik-tech/image/upload/v1690552527/Untitled%20design/pexels-kindel-media-7007170_mg9sd3.jpg" style="border-radius: 15px;" alt="">
          </div>
        </div>
        <div class="col-lg-6 py-3 wow fadeInRight">
          <div class="subhead">About Us</div>
          <h2 data-aos="flip-left" data-aos-duration="1000" class="title-section">Learn more about <span class="marked"> Tradey Bay </span></h2>
          <div class="divider"></div>
          <p><i style="color: #6750A4;" class="fas fa-arrow-alt-circle-right "></i> Tradey Bay is a dynamic marketplace platform powered by our parent company, <a style="text-decoration:none; color: #6750A4;"  href="https://sputniktech.co/"> Sputnik Tech</a>. Established with a vision to revolutionize the way we buy, sell, and trade goods and services, Tradey Bay embodies a commitment to diversity, convenience, and community.</p>
          <p><i style="color: #6750A4;" class="fas fa-arrow-alt-circle-right "></i> At our core, we believe in facilitating a space where every transaction is more than just an exchange—it's a connection. We champion sellers, empower buyers, and aim to make commerce easy, safe, and enjoyable for everyone, no matter what they're looking for.</p>
          <a  [routerLink]="['/contact-us']" class="btn btn-primary btn-split ml-2">Contact Us<div class="fab"><span class="fas fa-arrow-alt-circle-right" ></span></div></a>
          
        </div>
      </div>
    </div>
    
    <div class="custom-shape-divider-bottom-1667156080">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
          <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
          <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
      </svg>
  </div>
  <div class="custom-shape-divider-top-1667156508">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
  </div>
     
  </div> 


<section  data-scroll-index="0">
  <div class="container mb-5">
    <div class="row">
      <div class="col-md-12">
        <div class="welcome-content text-center">
          <h2 data-aos="flip-left" data-aos-duration="1000" class="title-section">FAQ's</h2>
          <div class="divider mx-auto"></div>

          <p style="text-align:center;">
            <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
            <lottie-player src="https://assets8.lottiefiles.com/packages/lf20_mwhmbyym.json" background="transparent"
              speed="1" style="width: 150px; height: 150px;" loop autoplay></lottie-player>
          </p>
        </div>
      </div>
      <div class="col-md-12">
        <div class="welcome-content color text-center">
          <div class="faq-wrapper">
            <details>
              <summary class="disable-select">What is Tradey Bay?</summary>
              <div>Tradey Bay is a versatile marketplace platform, powered by Sputnik Tech, where you can buy, sell, and trade a diverse range of goods and services, from real estate to personal services and so on.
                
              </div>
            </details>
            <details>
              <summary class="disable-select">How do I create an account on Tradey Bay?</summary>
              <div>
                Creating an account on Tradey Bay is quick and secure. Begin by clicking the 'Sign Up' button on our homepage and entering your phone number. After this, you'll receive a One-Time Password (OTP) on your phone for verification. Enter the OTP in the designated field, then you'll be prompted to enter some basic information about yourself. After filling out this information, complete your registration by clicking the 'Register' button. For subsequent logins, you simply need to enter your phone number and the OTP sent to you, eliminating the need for remembering passwords.
                             
              </div>
            </details>
            <details>
              <summary class="disable-select">How does the auction feature work?</summary>
              <div>Our real-time auction feature allows sellers to list their items for auction, and buyers to place bids on these items. The highest bid at the end of the auction duration secures the item.
              </div>
            </details>
            <details>
              <summary class="disable-select">Is my financial information safe on Tradey Bay?</summary>
              <div>Absolutely. Tradey Bay uses secure encryption and trusted payment gateway services to ensure all transactions and financial information are protected.
              </div>
            </details>
            <details>
              <summary class="disable-select">How can I contact a seller or buyer?</summary>
              <div>You can contact a seller or buyer directly through our in-app messaging service. Navigate to the 'Messages' page on our platform, select the seller or buyer you wish to contact, and you can start a conversation. Please remember to keep all communication respectful and professional. Tradey Bay's messaging service ensures a secure and convenient communication between buyers and sellers.               
              </div>
            </details>
            <details>
              <summary class="disable-select">How can I create an Ad/Auction Ad on Tradey Bay?</summary>
              <div>Creating an Ad or Auction Ad on Tradey Bay is straightforward and supported by AI technology. Here's how:  
                <br>
                <ul style="list-style: none;">
                  <li>1. Log in to your Tradey Bay account on our Android or iOS app.</li>
                  <li>2. Find the 'Add Ad' button at the center of the bottom navigation bar and tap it.</li>
                  <li>3. You will be directed to a page where you can fill out the required fields with the relevant information about your product or service. This includes a title, description, images, and pricing information. For Auction Ads, you'll also need to set a starting bid and auction end time.</li>
                  <li>4. If you need help crafting a compelling Ad name and description, our generative AI chat model is here to assist you. It provides suggestions for writing eye-catching titles and detailed, persuasive descriptions, increasing the effectiveness of your Ad.</li>
                  <li>5. Review your Ad or Auction Ad for any errors and ensure all information is accurate.</li>
                  <li>6. If you wish to increase the visibility of your Ad, you can opt to pay for our Ad boost feature. This puts your Ad in a prominent position on our platform, leading to increased visibility and potentially higher engagement.</li>
                  <li>7. Once you're satisfied with your Ad, tap 'Submit' or 'Post'. Your Ad will now be live on Tradey Bay and visible to all users.</li>
                  
                </ul>             
              </div>
            </details>
            <!-- <details>
              <summary class="disable-select">{{'FAQSQuestionSeven' | translate}}</summary>
              <div>{{'FAQSAnswerSeven' | translate}} </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionEight' | translate}}</summary>
              <div>{{'FAQSAnswerEight' | translate}}                 
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionNine' | translate}}</summary>
              <div>{{'FAQSAnswerNine' | translate}} 
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionTen' | translate}}</summary>
              <div>{{'FAQSAnswerTen' | translate}}
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionElleven' | translate}}</summary>
              <div>{{'FAQSAnswerElleven' | translate}}                
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionTwelve' | translate}}</summary>
              <div>{{'FAQSAnswerTwelve' | translate}}                
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionThirteen' | translate}}</summary>
              <div>{{'FAQSAnswerThirteen' | translate}}</div>
            </details> -->
          </div>
        </div>
      </div>
    </div>
    
  </div>
 
  <div class="custom-shape-divider-bottom-1667156080">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
</div>
<div class="custom-shape-divider-top-1667156508">
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
      <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
      <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
  </svg>
</div>
</section> 

</main>


