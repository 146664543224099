import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private metaTagService: Meta, ) {}


  ngOnInit(): void {
    

    this.metaTagService.addTags([
      {
        name: 'keywords',
    content: 'Tradey Bay, E-commerce, Online Marketplace, Online Auctions, Buy Sell Trade, Online Shopping, Ad Listings, Auction Ads, Homepage Carousel Ads, Homepage Stories Ads, Listing Boosting, Secure Online Shopping, Online Selling Platform, E-commerce Auctions, Product Listings, User-friendly E-commerce, Online Shopping Experience, Safe Online Transactions, Quality Product Listings, Fast Shipping Services, Efficient Customer Service, Trusted Online Marketplace, Product Bidding, Digital Shopping Platform, Trade Goods Online, List Products for Sale, Best Online Auctions, Ad Boosting Service, Carousel Ads Online, Online Ad Placements, E-commerce Promotions, Online Market Ads, Customer Dispute Resolution, Seller and Buyer Protection, Adverts, Advertise, Advertisements, Advertisers, Advertising, Classifieds, Classified Ads, Classified Advertisements, Classified Advertisers, Classified Advertising, Classified Listings, Classified Listings Ads, Classified Listings Advertisements, Classified Listings Advertisers, Classified Listings Advertising, Classified Listings Ads, Classified Listings Advertisements, Classified Listings Advertisers, Classified Listings Advertising, Classified Listings Ads, Classified Listings Advertisements, Classified Listings Advertisers, Classified Listings Advertising, Classified Listings Ads, Classified Listings Advertisements, Classified Listings Advertisers, Classified Listings Advertising, Classified Listings Ads, Classified Listings Advertisements, Classified Listings Advertisers, Classified Listings Advertising, Zimbabwe Ads, Zimbabwe Advertising, Harare Advertising'
      },
      { 
        name: 'robots', 
        content: 'index, follow' 
      },
      {
        name: 'description',
        content: 'Tradey Bay is an innovative online marketplace and e-commerce platform where you can buy, sell, and trade a wide variety of products. With unique features like Auction Ads, Homepage Carousel Ads, and Listing Boosting, Tradey Bay offers a superior online shopping experience.'
      },
      { 
        name: 'author', 
        content: 'Tradey Bay' 
      },
      { 
        name: 'twitter:card', 
        content: 'Tradey Bay' 
      },
      {
        name: 'twitter:title',
        content: 'Tradey Bay - The Future of Online Shopping and E-commerce'
      },
      {
        name: 'twitter:description',
        content: 'Tradey Bay is an innovative online marketplace and e-commerce platform where you can buy, sell, and trade a wide variety of products. With unique features like Auction Ads, Homepage Carousel Ads, and Listing Boosting, Tradey Bay offers a superior online shopping experience.'
      },
    ]);
    
  }
}
