import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from '../services/canonical.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {

  constructor(
    private title: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService,
  ) { }

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.title.setTitle('Terms and Conditions');
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Tradey Bay terms and conditions',
    });
  }

}
